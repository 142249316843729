<template>
  <div class="g_page_box">
    <div class="g_main_content main_content">
      <g-loading :loading="loading" />
      <img src="../../assets/img/pay_success.png" class="icon" width="153" />
      <div class="title">预约成功</div>
      <div class="surplus_time">
        请在{{format_date(data.date, 'yyyy年MM月dd日')}} {{data.am_pm}}当日 在{{data.hospital_name}}门诊缴费处或
        互联网医院缴费入口进行缴费
      </div>
    </div>
    <router-link :to="{path: '/registration-record'}">
      <div class="bottom_box">
        <div class="btn">我的挂号</div>
      </div>
    </router-link>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { format_date } from "../../utils/format";
export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("appointment", {
      data: (state) => state.appointment_info,
    }),
  },
  components: {},
  watch: {},
  created() {
  },
  destroyed() {},
  methods: {
    format_date
  },
};
</script>
<style scoped>
.main_content {
  text-align: center;
}
.icon {
  margin-top: 65px;
}
.title {
  font-size: 19px;
  font-weight: 600;
  line-height: 27px;
  color: #242938;
  margin-top: 28px;
}
.surplus_time {
  font-size: 14px;
  line-height: 20px;
  color: #7B7B7B;
  margin: 8px auto;
  max-width: 200px;
  & label {
    color: #0088ff;
  }
}
.bottom_box {
  padding: 24px 13px;
  & .btn {
    width: 100%;
    background: #0088ff;
    border-radius: 20px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>
